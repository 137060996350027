.contact-page__body {
  position: relative;
  background: #ffffff;
  z-index: 100;

  @include pc {
    width: calc(100% - 120px);
    padding: 100px calc((100% - 1240px) / 2) 110px;
    margin: 0 60px;
  }

  @include tab {
    padding: 100px calc((100% - 750px) / 2) 100px;
    margin: 116px 60px 12px;
  }

  @include l-sp {
    padding: 48px calc((100% - 500px) / 2) 60px;
    margin: 0 40px 12px;
  }

  @include sp {
    padding: 50px calc((100% - 375px) / 2) 50px;
    margin: 58px 30px 6px;
  }

  p {
    @include pc {
      font-size: 16px;
      margin: 0 40px 24px;
      line-height: 2;
    }

    @include tab {
      font-size: 28px;
      margin-bottom: 68px;
      line-height: 2;
    }

    @include l-sp {
      font-size: 18px;
      margin-bottom: 38px;
      line-height: 2.2;
    }

    @include sp {
      font-size: 14px;
      margin-bottom: 34px;
      line-height: 2;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    @include pc {
      margin: 0 40px;
    }

    small {
      display: inline-block;

      @include pc {
        margin-top: -12px;
        margin-bottom: 26px;
      }

      @include tab {
        font-size: 22px;
        margin-top: -4px;
        margin-bottom: 36px;
      }

      @include l-sp {
        font-size: 14px;
        margin-top: -8px;
        margin-bottom: 28px;
      }

      @include sp {
        font-size: 11px;
        margin-top: -2px;
        margin-bottom: 18px;
      }
    }

    .small_link {
      color: #009CCE;
    }

    label {
      font-weight: 800;

      @include pc {
        font-size: 16px;
        margin: 46px 0 24px;
      }

      @include tab {
        font-size: 28px;
        margin: 68px 0 32px;

        &:first-of-type {
          margin-top: 0;
        }
      }

      @include l-sp {
        font-size: 18px;
        margin: 48px 0 22px;
      }

      @include sp {
        font-size: 14px;
        margin: 34px 0 16px;
      }

      sup {
        color: #ff5050;
      }
    }

    input {
      width: 100%;
      background: #f3faff;
      box-sizing: border-box;

      @include pc {
        height: 100px;
        padding: 40px 40px;
        font-size: 16px;
      }

      @include tab {
        height: 122px;
        padding: 40px 40px;
        font-size: 28px;
      }

      @include l-sp {
        height: 80px;
        padding: 40px 28px;
        font-size: 20px;
      }

      @include sp {
        height: 61px;
        padding: 20px 20px;
        font-size: 14px;
      }

      &::placeholder {
        color: #bfc7d0;
      }

      &:-ms-input-placeholder {
        color: #bfc7d0;
      }

      &:focus {
        background: #ffffff;
        border: solid 2px #3aa6f3;
      }
    }

    .error {
      border: solid 2px #ff5050;
      background: #fffafa;
    }

    > p {
      margin-bottom: 0;
      text-align: center;
      letter-spacing: 0.05em;

      @include pc {
        margin-top: 58px;
      }

      @include tab {
        margin-top: 72px;
      }

      @include l-sp {
        margin-top: 48px;
      }

      @include sp {
        margin-top: 24px;
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"] + label span::before {
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
        border-radius: 3px;
        background: #eaf6ff;
        z-index: 0;

        @include pc {
          width: 26px;
          height: 26px;
          left: -41px;
          top: -3px;
        }

        @include tab {
          width: 32px;
          height: 32px;
          left: -59px;
          top: 4px;
        }

        @include l-sp {
          width: 20px;
          height: 20px;
          left: -32px;
          top: 3px;
        }

        @include sp {
          width: 16px;
          height: 16px;
          left: -29.5px;
          top: 2px;
        }
      }

      label {
        position: relative;
        margin-left: 18px;
      }

      input[type="checkbox"]:checked + label::before {
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
        border-radius: 8px;
        transform: rotate(46deg);
        background-color: #00beff;
        z-index: 100;

        @include pc {
          width: 10px;
          height: 3px;
          margin-top: -6px;
          margin-left: -37px;
          top: 16px;
          left: 0;
        }

        @include tab {
          width: 14px;
          height: 4px;
          margin-top: 2px;
          margin-left: -56px;
          top: 16px;
          left: 0;
        }

        @include l-sp {
          width: 10px;
          height: 3px;
          margin-top: -4px;
          margin-left: -31px;
        }

        @include sp {
          width: 7px;
          height: 2px;
          margin-top: 1px;
          margin-left: -28px;
          top: 8px;
          left: 0;
        }
      }

      input[type="checkbox"]:checked + label::after {
        content: "";
        position: absolute;
        display: block;
        box-sizing: border-box;
        border-radius: 8px;
        transform: rotate(-46deg);
        background-color: #00beff;
        z-index: 100;

        @include pc {
          width: 14px;
          height: 3px;
          margin-top: -6px;
          margin-left: -37px;
          top: 15px;
          left: 5px;
        }

        @include tab {
          width: 18px;
          height: 4px;
          margin-top: 2px;
          margin-left: -54px;
          top: 15px;
          left: 5px;
        }

        @include l-sp {
          width: 13px;
          height: 3px;
          margin-top: -4px;
          margin-left: -31px;
        }

        @include sp {
          width: 9px;
          height: 2px;
          margin-top: 1px;
          margin-left: -27px;
          top: 7.5px;
          left: 2.5px;
        }
      }

      .agree-link {
        color: #00beff;
      }
    }

    .error-message {
      display: none;
      color: red;
      font-weight: 800;

      @include pc {
        font-size: 16px;
        padding: 12px 0 18px;
      }

      @include tab {
        font-size: 28px;
        padding: 28px 0 0;
      }

      @include l-sp {
        font-size: 18px;
        padding: 20px 0 0;
      }

      @include sp {
        font-size: 14px;
        padding: 14px 0 0;
      }
    }

    .error-show {
      display: block;
    }

    button {
      background: linear-gradient(to right, #00beff, #0096ff);
      color: #ffffff;
      text-align: center;
      border-radius: 4px;
      font-weight: 800;

      @include pc {
        margin: 58px calc((100% - 236px) / 2) 0;
        width: 236px;
        height: 76px;
        font-size: 18px;
      }

      @include tab {
        margin: 68px calc((100% - 385px) / 2) 0;
        width: 385px;
        height: 110px;
        font-size: 32px;
      }

      @include l-sp {
        margin: 44px calc((100% - 260px) / 2) 0;
        width: 260px;
        height: 72px;
        font-size: 22px;
      }

      @include sp {
        margin: 34px calc((100% - 192.5px) / 2) 0;
        width: 192.5px;
        height: 55px;
        font-size: 16px;
      }
    }
  }

  .hidden {
    display:none;
  }
}
