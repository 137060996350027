.document-download {
  width: 100%;
  background: #e9f6fe;

  &__inner {
    @include pc {
      padding: 0 calc((100% - 1080px) / 2) 110px;
      margin: 0 60px;
    }

    @include tab {
      margin: 0 80px;
      padding: 0 calc((100% - 750px) / 2) 110px;
    }

    @include l-sp {
      margin: 0 52px;
      padding: 0 calc((100% - 500px) / 2) 80px;
    }

    @include sp {
      margin: 0 40px;
      padding: 0 calc((100% - 375px) / 2) 55px;
    }
  }

  .download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: -moz-linear-gradient(-48deg, rgb(0, 190, 255) 0%, rgb(0, 150, 255) 100%);
    background: -webkit-linear-gradient(-48deg, rgb(0, 190, 255) 0%, rgb(0, 150, 255) 100%);
    background: -ms-linear-gradient(-48deg, rgb(0, 190, 255) 0%, rgb(0, 150, 255) 100%);

    @include pc {
      width: 100%;
      height: 157px;
      border-radius: 3px;
    }

    @include tab {
      padding: 40px 80px;
      border-radius: 6px;
    }

    @include l-sp {
      padding: 21px 60px;
      border-radius: 6px;
    }

    @include sp {
      padding: 20px 40px;
    }

    &__text {
      text-align: center;
      color: #ffffff;
      font-weight: 800;
      line-height: 2em;

      @include pc {
        font-size: 18px;
      }

      @include tab {
        font-size: 22px;
      }

      @include l-sp {
        font-size: 16px;
      }

      @include sp {
        font-size: 11px;
      }
    }

    &__large-text {
      @include pc {
        font-size: 26px;
      }

      @include tab {
        font-size: 28px;
      }

      @include l-sp {
        font-size: 18px;
      }

      @include sp {
        font-size: 14px;
      }
    }
  }
}
