.kv {
  width: 100%;

  @include pc {
    height: calc(100vh - 70px);
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    position: relative;

    @include pc {
      display: flex;
      align-items: center;
      height: calc(100vh - 70px);
      margin-left: 101px;
    }

    @include tab {
      margin: 0 40px;
    }

    @include l-sp {
      margin: 0 24px;
    }

    @include sp {
      margin: 0 20px;
    }
  }
}

.kv-copy {
  position: relative;

  &__head {
    font-weight: 800;

    @include pc {
      margin-bottom: 23px;
      margin-left: 152px;
      font-size: 20px;
      line-height: 1.67;
      letter-spacing: 0.12em;

      @include lineHeightCrop(1.67);
    }

    @include tab {
      font-size: 28px;
      line-height: 1.04;
      letter-spacing: 0.06em;
      margin: 128px 0 16px 168px;

      @include lineHeightCrop(1.04);
    }

    @include l-sp {
      font-size: 18px;
      margin: 84px 0 16px 110px;
    }

    @include sp {
      font-size: 14px;
      margin: 64px 0 8px 84px;
    }
  }

  &__foot {
    display: inline;
    font-weight: 800;
    color: #ffffff;
    background: #000000;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;

    @include pc {
      font-size: 38px;
      line-height: 2.08;
      letter-spacing: 0.12em;
      padding: 8px 9px;
    }

    @include tab {
      font-size: 52px;
      line-height: 1.67;
      letter-spacing: 0.06em;
      padding: 3px 12px;
    }

    @include l-sp {
      font-size: 34px;
      line-height: 1.75;
    }

    @include sp {
      font-size: 26px;
      line-height: 1.66;
      padding: 2px 7px;
      letter-spacing: 0.04em;
    }

    .blue {
      position: relative;

      sup {
        position: absolute;

        @include pc {
          top: 2px;
          right: -9px;
          font-size: 10px;
        }

        @include tab {
          font-size: 16px;
          top: 8px;
          right: -8px;
        }

        @include l-sp {
          font-size: 10px;
          top: 7px;
          right: -7px;
        }

        @include sp {
          font-size: 10px;
          transform: scale(0.8);
          top: 5px;
          right: -7px;
        }
      }
    }
  }

  &__lead {
    li {
      display: inline-block;
      border-right: 1px solid #e7e7e7;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        border: 0;
      }

      @include pc {
        transform: skewX(160deg);
        margin-top: 45px;
        padding: 0 24px 0 30px;
        height: 50px;
      }

      @include tab {
        transform: skewX(165deg);
        margin-top: 50px;
        padding: 0 32px 0 28px;
      }

      @include l-sp {
        margin-top: 28px;
        padding: 0 20px 0 20px;
      }

      @include sp {
        margin-top: 22.5px;
        padding: 0 14px 0 10px;
        height: 36px;
      }

      > span {
        display: block;
        font-weight: 800;
        margin-left: 0;

        @include pc {
          transform: skewX(-160deg);
          margin-left: -8px;
          font-size: 14px;
          line-height: 2.38;
          letter-spacing: 0.1em;

          @include lineHeightCrop(2.38);
        }

        @include tab {
          transform: skewX(-165deg);
          margin-left: -8px;
          font-size: 22px;
          line-height: 1.52;

          @include lineHeightCrop(1.52);
        }

        @include l-sp {
          font-size: 16px;
        }

        @include sp {
          font-size: 11px;
          margin-left: -2px;
        }
      }

      p {
        font-weight: 800;
        color: #00beff;

        @include pc {
          transform: skewX(-160deg);
          margin-left: -2px;
          margin-top: 8px;
          font-size: 34px;
          line-height: 0.98;

          @include lineHeightCrop(0.98);
        }

        @include tab {
          transform: skewX(-165deg);
          margin-left: -4px;
          margin-top: 12px;
          font-size: 44px;
          line-height: 0.76;
        }

        @include l-sp {
          font-size: 30px;
        }

        @include sp {
          font-size: 22px;
          margin-left: 0;
          margin-top: 8px;
        }

        > span {
          @include pc {
            font-size: 16px;
            line-height: 2.09;
          }

          @include tab {
            font-size: 24px;
            line-height: 1.39;
          }

          @include l-sp {
            font-size: 20px;
          }

          @include sp {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__img {
    z-index: -100;

    @include pc {
      position: absolute;
      top: calc(50% - 35px);
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      right: 100px;
      height: 540px;
      min-height: 540px;
    }

    @include tab {
      width: 700px;
      margin-top: 12px;
    }

    @include l-sp {
      width: 100%;
    }
  }

  &__caption {
    position: absolute;

    @include pc {
      bottom: 30px;
      left: 0;
      font-size: 12px;
    }

    @include tab {
      position: static;
      display: inline-block;
      font-size: 20px;
      letter-spacing: 0.1em;
      margin: 44px 0 40px;
    }

    @include l-sp {
      font-size: 14px;
      margin: 35px 0 28px;
    }

    @include sp {
      font-size: 10px;
      margin: 30px 0 18px;
    }
  }

  &__pr {
    position: absolute;
    background: #ff63a0;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;

    @include pc {
      width: 134px;
      height: 134px;
      top: -72px;
      left: 0;
    }

    @include tab {
      width: 146px;
      height: 146px;
      top: -70px;
      left: 0;
    }

    @include l-sp {
      width: 100px;
      height: 100px;
      top: -52px;
      left: 0;
    }

    @include sp {
      width: 73px;
      height: 73px;
      top: -35px;
      left: 0;
    }

    .pr-text {
      font-weight: bold;
      color: #ffffff;
      line-height: 2em;

      @include pc {
        font-size: 14px;
        letter-spacing: 0.1em;
      }

      @include tab {
        font-size: 16px;
        letter-spacing: 0.1em;
      }

      @include l-sp {
        font-size: 10px;
      }

      @include sp {
        transform: scale(0.8);
      }

      .pr-large {
        font-weight: 800;

        @include pc {
          font-size: 30px;
        }

        @include tab {
          font-size: 34px;
        }

        @include l-sp {
          font-size: 21px;
        }

        @include sp {
          font-size: 17px;
        }
      }

      .pr-small {
        font-weight: bold;

        @include pc {
          font-size: 18px;
        }

        @include tab {
          font-size: 20px;
        }

        @include l-sp {
          font-size: 18px;
        }

        @include sp {
          font-size: 10px;
        }
      }
    }
  }
}
