// 下層ページのサブヘッダー(書くファイル作って書いた方が良さそう...)
.sub-page {
  &__inner {
    @include pc {
      padding: 0 calc((100% - 1440px) / 2);
      min-height: calc(100vh - 339px);
    }

    @include tab {
      padding: 0 calc((100% - 750px) / 2);
      min-height: calc(100vh - 476px);
    }

    @include l-sp {
      padding: 0 calc((100% - 500px) / 2);
      min-height: calc(100vh - 318px);
    }

    @include sp {
      padding: 0 calc((100% - 375px) / 2);
      min-height: calc(100vh - 237px);
    }
  }

  &__header {
    position: relative;

    @include pc {
      height: 400px;
      margin: 0 100px;
      padding-top: 128px;
    }

    @include tab {
      height: 420px;
      margin: 0 40px;
      padding-top: 128px;
    }

    @include l-sp {
      height: 380px;
      margin: 0 28px;
      padding-top: 93px;
    }

    @include sp {
      height: 210px;
      margin: 0 20px;
      padding-top: 64px;
    }

    p {
      position: relative;
      z-index: 50;
      display: inline;
      background: #000000;
      letter-spacing: 0.2rem;
      color: #ffffff;
      font-weight: 800;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;

      @include pc {
        font-size: 38px;
        padding: 8px 14px;
        line-height: 2.1;
      }

      @include tab {
        font-size: 52px;
        letter-spacing: 0.05em;
        padding: 4px 14px;
        line-height: 1.75;
      }

      @include l-sp {
        font-size: 32px;
        padding: 4px 10px;
        line-height: 1.8;
      }

      @include sp {
        font-size: 26px;
        padding: 2px 7px;
        line-height: 1.75;
      }
    }

    img {
      position: absolute;
      z-index: 0;

      @include pc {
        height: 660px;
        top: -18px;
        right: -10px;
      }

      @include tab {
        height: 520px;
        top: 0;
        right: -60px;
      }

      @include l-sp {
        height: 380px;
        top: 0;
        right: -80px;
      }

      @include sp {
        height: 260px;
        top: 0;
        right: -30px;
      }
    }
  }
}
