.about {
  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
    overflow: hidden;
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    @include pc {
      display: flex;
      padding-left: calc((100% - 1080px) / 2);
      padding-right: calc((100% - 1080px) / 2);
      margin: 0 60px;
    }

    @include tab {
      margin: 0 60px;
    }

    @include l-sp {
      margin: 0 40px;
    }

    @include sp {
      margin: 0 30px;
    }
  }

  &__inner--head {
    @include pc {
      padding-top: 110px;
      padding-bottom: 92px;
    }

    @include tab {
      padding-top: 110px;
      padding-bottom: 76px;
    }

    @include l-sp {
      padding-top: 72px;
      padding-bottom: 44px;
    }

    @include sp {
      padding-top: 55px;
      padding-bottom: 38px;
    }

    .about__text {
      @include pc {
        order: 1;
      }
    }

    .about__image {
      @include pc {
        order: 2;
        width: calc(100% - 460px - 32px);
        margin: 76px 0 0 32px;
      }

      @include tab {
        width: 693px;
      }

      @include l-sp {
        width: 460px;
      }

      @include sp {
        width: calc(693px / 2);
      }

      img {
        @include pc {
          width: 100%;
          margin-left: 40px;
        }

        @include tab {
          width: 100%;
          margin-top: 140px;
          margin-left: -20px;
        }

        @include l-sp {
          margin-top: 100px;
          margin-left: -12px;
        }

        @include sp {
          width: 80%;
          margin-top: 70px;
          margin-left: -10px;
        }
      }
    }
  }

  &__inner--foot {
    @include pc {
      padding-bottom: 100px;
    }

    @include tab {
      padding-bottom: 40px;
    }

    @include sp {
      padding-bottom: 20px;
    }

    .about__image {
      @include pc {
        width: calc(100% - 460px - 32px);
        margin: 0 32px 0 0;
        order: 1;
      }

      @include tab {
        width: 100%;
      }

      img {
        @include pc {
          width: 100%;
          margin-left: -40px;
        }

        @include tab {
          width: 100%;
          margin-top: 32px;
        }

        @include sp {
          margin-top: 20px;
        }
      }
    }
  }

  /* stylelint-disable-next-line */
  &__text {
    order: 2;

    @include pc {
      width: 460px;
    }

    h2 {
      font-weight: 800;

      @include pc {
        font-size: 32px;
        line-height: 2.14;
        letter-spacing: 0.06em;
        margin-bottom: 55px;

        @include lineHeightCrop(2.14);
      }

      @include tab {
        font-size: 40px;
        line-height: 1.6;
        letter-spacing: 0.06em;

        @include lineHeightCrop(1.6);
      }

      @include l-sp {
        font-size: 26px;
      }

      @include sp {
        font-size: 20px;
      }
    }

    p {
      @include pc {
        font-size: 16px;
        line-height: 2;
        margin-top: 32px;

        @include lineHeightCrop(2);
      }

      @include tab {
        font-size: 28px;
        line-height: 2.07;
        margin-top: 52px;

        @include lineHeightCrop(2.07);

        &:first-of-type {
          margin-top: 68px;
        }
      }

      @include l-sp {
        font-size: 19px;
        margin-top: 32px;
        line-height: 2.1;

        @include lineHeightCrop(2.1);

        &:first-of-type {
          margin-top: 32px;
        }
      }

      @include sp {
        font-size: 14px;
        margin-top: 26px;
      }
    }

    small {
      display: inline-block;

      @include pc {
        font-size: 14px;
        line-height: 2;
        margin-top: 40px;

        @include lineHeightCrop(2);
      }

      @include tab {
        font-size: 20px;
        line-height: 2;
        margin-top: 72px;

        @include lineHeightCrop(2);
      }

      @include l-sp {
        font-size: 14px;
        margin-top: 40px;
      }

      @include sp {
        font-size: 10px;
        margin-top: 32px;
      }
    }
  }
  // 追加項目
  // pタグとかsmallとか上とほぼ同じだけどリリース時期迫ってるので一旦実装、あとで調整したい
  &__attention {
    @include pc {
      display: flex;
      width: calc(100% - 240px);
      margin: 0 120px 110px;
      background: #e9f6fe;
      padding: 75px 120px;
      border-radius: 12px;
    }

    @include tab {
      padding: 0 calc((100% - 670px) / 2);
      margin-bottom: 110px;
    }

    @include l-sp {
      padding: 0 calc((100% - 440px) / 2);
      margin-bottom: 76px;
    }

    @include sp {
      padding: 0 calc((100% - 335px) / 2);
      margin-bottom: 55px;
    }

    .attention-text {
      @include pc {
        margin-right: 72px;
      }

      @include tab {
        background: #e9f6fe;
        padding: 80px 60px;
        border-radius: 12px;
      }

      @include l-sp {
        padding: 50px 40px;
        border-radius: 12px;
      }

      @include sp {
        padding: 40px 30px;
        border-radius: 6px;
      }

      h3 {
        font-weight: 800;

        @include pc {
          font-size: 26px;
        }

        @include tab {
          font-size: 32px;
        }

        @include l-sp {
          font-size: 20px;
        }

        @include sp {
          font-size: 16px;
        }
      }

      p {
        @include pc {
          font-size: 16px;
          line-height: 2;
          margin-top: 32px;

          @include lineHeightCrop(2);
        }

        @include tab {
          font-size: 28px;
          line-height: 2.07;
          margin-top: 52px;

          @include lineHeightCrop(2.07);
        }

        @include l-sp {
          font-size: 19px;
          margin-top: 32px;
          line-height: 2.1;

          @include lineHeightCrop(2.1);
        }

        @include sp {
          font-size: 14px;
          margin-top: 26px;
        }
      }

      small {
        display: inline-block;

        @include pc {
          font-size: 14px;
          line-height: 2;
          margin-top: 40px;

          @include lineHeightCrop(2);
        }

        @include tab {
          font-size: 20px;
          line-height: 2;
          margin-top: 72px;

          @include lineHeightCrop(2);
        }

        @include l-sp {
          font-size: 14px;
          margin-top: 40px;
        }

        @include sp {
          font-size: 10px;
          margin-top: 36px;
        }
      }
    }

    .attention-image {
      @include pc {
        height: 280px;
      }
    }
  }
}
