.thanks-page__body {
  position: relative;
  background: #ffffff;
  z-index: 100;

  @include pc {
    width: calc(100% - 120px);
    padding: 100px calc((100% - 1160px) / 2) 110px;
    margin: 0 60px;
  }

  @include tab {
    padding: 100px calc((100% - 750px) / 2) 100px;
    margin: 116px 60px 12px;
  }

  @include l-sp {
    padding: 48px calc((100% - 500px) / 2) 60px;
    margin: 0 40px 12px;
  }

  @include sp {
    padding: 50px calc((100% - 375px) / 2) 50px;
    margin: 58px 30px 6px;
  }

  p {
    @include pc {
      font-size: 16px;
      line-height: 2;
    }

    @include tab {
      font-size: 28px;
      line-height: 2.2;
    }

    @include l-sp {
      font-size: 18px;
    }

    @include sp {
      font-size: 14px;
    }
  }

  .top-link {
    display: inline-block;
    background: #00beff;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 800;
    transition: all 0.3s ease 0s;

    @include pc {
      padding: 26px 28px;
      font-size: 16px;
      margin-top: 40px;
    }

    @include tab {
      padding: 32px 47px;
      font-size: 28px;
      margin-top: 58px;
    }

    @include l-sp {
      padding: 20px 25px;
      font-size: 20px;
      margin-top: 40px;
    }

    @include sp {
      padding: 16px 23.5px;
      font-size: 14px;
      margin-top: 29px;
    }

    &:hover {
      background: #19c4ff;
    }
  }
}
