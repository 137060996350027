.contact {
  width: 100%;

  &__inner {
    @include pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 110px calc((100% - 960px) / 2);
    }

    @include tab {
      padding: 110px calc((100% - 750px) / 2);
      margin: 0 58px;
    }

    @include l-sp {
      padding: 70px calc((100% - 500px) / 2);
      margin: 0 40px;
    }

    @include sp {
      padding: 55px calc((100% - 333px) / 2);
      margin: 0 10px;
    }

    h2 {
      width: 100%;
      font-weight: 800;
      text-align: center;

      @include pc {
        margin-bottom: 60px;
        font-size: 32px;
      }

      @include tab {
        margin-bottom: 74px;
        font-size: 40px;
      }

      @include l-sp {
        margin-bottom: 48px;
        font-size: 28px;
      }

      @include sp {
        margin-bottom: 37px;
        font-size: 20px;
      }
    }

    .contact-method {
      &__mail,
      &__tel {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include pc {
          width: 480px;
          padding: 58px 0 60px;
        }

        @include tab {
          width: 100%;
          padding: 82px 0 100px;
        }

        @include l-sp {
          padding: 52px 0 62px;
        }

        @include sp {
          padding: 41px 0 50px;
        }

        h3 {
          font-weight: 800;
          line-height: 1.8;

          @include pc {
            margin-bottom: 20px;
            font-size: 24px;
          }

          @include tab {
            margin-bottom: 46px;
            font-size: 36px;
          }

          @include l-sp {
            margin-bottom: 25px;
            font-size: 28px;
          }

          @include sp {
            margin-bottom: 23px;
            font-size: 18px;
          }
        }

        p {
          text-align: center;
          line-height: 1.8;

          @include pc {
            margin-bottom: 36px;
          }

          @include tab {
            font-size: 28px;
            line-height: 2;
            margin-bottom: 32px;
            letter-spacing: 0.06em;
          }

          @include l-sp {
            font-size: 19px;
            margin: 0 12px 24px 12px;
          }

          @include sp {
            font-size: 14px;
            margin-bottom: 16px;
          }
        }
      }

      &__mail {
        background: #00beff;

        h3,
        p {
          color: #ffffff;
        }

        a {
          display: block;
          border-radius: 4px;
          background: #00beff;
          color: #ffffff;
          text-align: center;
          font-weight: 800;
          transition: all 0.3s ease 0s;

          @include pc {
            // padding: 3px 32px;
            line-height: 64px;
            font-size: 24px;
          }

          @include tab {
            font-size: 32px;
            letter-spacing: 0.06em;
          }

          @include l-sp {
            font-size: 20px;
          }

          @include sp {
            font-size: 16px;
          }
        }

        // a:hover {
        //   background: #19c4ff;
        // }
      }

      &__tel {
        background: #e9f6fe;

        /* stylelint-disable-next-line */
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          color: #009bff;
          letter-spacing: 0.1rem;
          margin-bottom: 0;

          @include pc {
            font-size: 24px;
            padding: 16px 32px 0;
          }

          @include tab {
            font-size: 32px;
            line-height: 1.3;

            @include lineHeightCrop(1.3);
          }

          @include l-sp {
            font-size: 26px;
          }

          @include sp {
            font-size: 16px;
          }

          img {
            @include pc {
              height: 40px;
              margin-right: 18px;
            }

            @include tab {
              height: 48px;
              margin-right: 28px;
            }

            @include l-sp {
              height: 32px;
              margin-right: 22px;
            }

            @include sp {
              height: 24px;
              margin-right: 14px;
            }
          }
        }
      }
    }
  }
}
