.option {
  width: 100%;
  background: #e9f6fe;

  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    @include pc {
      padding: 0 calc((100% - 1320px) / 2) 50px;
      margin: 0 60px;
    }

    @include tab {
      padding: 0 24px 60px;
      letter-spacing: 0.06em;
    }

    @include l-sp {
      padding: 0 18px 38px;
    }

    @include sp {
      padding: 0 12px 51.5px;
    }

    .option-plan {
      width: 100%;
      background: #dceef9;
      border-radius: 12px;

      @include pc {
        padding: 68px 80px 70px;
      }

      @include tab {
        padding: 83px 60px 40px;
      }

      @include l-sp {
        padding: 60px 38px 26px;
      }

      @include sp {
        padding: 41.5px 30px 24px;
      }

      h2 {
        font-weight: 800;
        color: #5da7d3;

        @include pc {
          font-size: 22px;
        }

        @include tab {
          font-size: 32px;
          line-height: 1.63;

          @include lineHeightCrop(1.63);
        }

        @include l-sp {
          font-size: 22px;
        }

        @include sp {
          font-size: 16px;
        }
      }

      .option-list {
        @include pc {
          display: flex;
          margin-top: 47px;
        }

        @include tab {
          margin-top: 60px;
        }

        @include l-sp {
          margin-top: 36px;
        }

        @include sp {
          margin-top: 30px;
        }

        li {
          @include pc {
            width: calc((100% - 120px) / 3);
            margin-right: 60px;
          }

          h3 {
            font-weight: 800;
            color: #7eb5d7;

            @include pc {
              font-size: 18px;
              margin-bottom: 32px;
            }

            @include tab {
              font-size: 28px;
              line-height: 2.07;
              margin-bottom: 32px;

              @include lineHeightCrop(2.07);
            }

            @include l-sp {
              font-size: 20px;
              margin-bottom: 20px;
            }

            @include sp {
              font-size: 14px;
              margin-bottom: 16px;
            }
          }

          p {
            font-weight: 400;

            @include pc {
              font-size: 16px;
              line-height: 2.5;

              @include lineHeightCrop(2.5);
            }

            @include tab {
              font-size: 28px;
              line-height: 2.07;
              margin-bottom: 52px;

              @include lineHeightCrop(2.07);
            }

            @include l-sp {
              font-size: 19px;
              margin-bottom: 32px;
            }

            @include sp {
              font-size: 14px;
              margin-bottom: 26px;
            }
          }
        }

        li:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
