header {
  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  .header__inner {
    @include pc {
      padding: 40px 0 0 40px;
    }

    @include tab {
      padding: 40px 0 0 40px;
    }

    @include l-sp {
      padding: 27px 0 0 27px;
    }

    @include sp {
      padding: 20px 0 0 20px;
    }

    img {
      height: 30px;

      @include tab {
        height: 36px;
      }

      @include l-sp {
        height: 25px;
      }

      @include sp {
        height: 18px;
      }
    }
  }
}
