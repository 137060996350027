.flow {
  width: 100%;

  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    @include pc {
      padding: 110px calc((100% - 870px) / 2) 62px;
    }

    @include tab {
      padding: 118px 60px 20px;
    }

    @include l-sp {
      padding: 78px 40px 10px;
    }

    @include sp {
      padding: 59px 30px 10px;
    }

    h2 {
      font-weight: 800;
      text-align: center;

      @include pc {
        margin-bottom: 60px;
        font-size: 32px;
      }

      @include tab {
        margin-bottom: 70px;
        font-size: 40px;
      }

      @include l-sp {
        margin-bottom: 50px;
        font-size: 28px;
      }

      @include sp {
        margin-bottom: 35px;
        font-size: 20px;
      }
    }

    .flow-publish {
      @include pc {
        display: flex;
        justify-content: space-between;
      }

      li {
        position: relative;
        display: flex;

        @include pc {
          flex-direction: column;
          width: 220px;
        }

        @include tab {
          width: 100%;
          margin-bottom: 100px;
        }

        @include sp {
          margin-bottom: 50px;
        }

        .text {
          order: 2;

          @include tab {
            width: calc(100% - 220px);
            padding-left: 60px;
          }

          @include l-sp {
            width: calc(100% - 148px);
            padding-left: 36px;
          }

          @include sp {
            width: calc(100% - 110px);
            padding-left: 30px;
          }

          h3 {
            font-weight: 800;
            text-align: center;

            @include pc {
              margin-bottom: 24px;
              font-size: 24px;
            }

            @include tab {
              text-align: left;
              margin-bottom: 28px;
              font-size: 36px;
            }

            @include l-sp {
              font-size: 23px;
              margin-bottom: 20px;
            }

            @include sp {
              margin-bottom: 14px;
              font-size: 18px;
            }
          }

          p {
            @include pc {
              order: 3;
              font-size: 16px;
              line-height: 2.5;
            }

            @include tab {
              font-size: 28px;
              line-height: 2.07;
            }

            @include l-sp {
              font-size: 18px;
            }

            @include sp {
              font-size: 14px;
            }
          }
        }

        .image {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #e9f6fe;
          order: 1;

          @include pc {
            width: 220px;
            height: 220px;
            margin-bottom: 32px;
          }

          @include tab {
            width: 220px;
            height: 220px;
          }

          @include l-sp {
            width: 148px;
            height: 148px;
          }

          @include sp {
            width: 110px;
            height: 110px;
          }
        }
      }

      li:last-child {
        margin-right: 0;
      }

      li:nth-child(2)::before,
      li:nth-child(3)::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent #cfe3fe;

        @include pc {
          top: 88px;
          left: -64px;
          border-width: 27px 0 27px 22px;
        }

        @include tab {
          top: -80px;
          left: 98px;
          border-width: 27px 0 27px 22px;
          transform: rotate(90deg);
        }

        @include l-sp {
          top: -52px;
          left: 68px;
          border-width: 20px 0 20px 16px;
        }

        @include sp {
          top: -40px;
          left: 49px;
          border-width: 13.5px 0 13.5px 11px;
        }
      }

      li:nth-child(1) .image::before,
      li:nth-child(2) .image::before,
      li:nth-child(3) .image::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        border-radius: 50%;
        text-align: center;
        font-weight: 800;
        color: #00beff;
        background: #ffffff;

        @include pc {
          width: 60px;
          height: 60px;
          box-shadow: 0 24px 40px #e8f2f5, -8px -8px 10px 0 rgba(232, 243, 246, $alpha: 0.3);
          line-height: 60px;
          font-size: 24px;
        }

        @include tab {
          width: 60px;
          height: 60px;
          box-shadow: 0 24px 40px #e8f2f5, -8px -8px 10px 0 rgba(232, 243, 246, $alpha: 0.3);
          line-height: 60px;
          font-size: 24px;
        }

        @include l-sp {
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-size: 16px;
        }

        @include sp {
          width: 30px;
          height: 30px;
          line-height: 30px;
          font-size: 12px;
        }
      }

      li:nth-child(1) {
        .image {
          img {
            @include pc {
              height: 120px;
              margin-bottom: -32px;
            }

            @include tab {
              height: 115px;
              margin-bottom: -30px;
            }

            @include l-sp {
              height: 88px;
            }

            @include sp {
              height: 57.5px;
              margin-bottom: -15px;
            }
          }
        }

        .image::before {
          content: "1";
        }
      }

      li:nth-child(2) {
        .image {
          img {
            @include pc {
              height: 180px;
              margin-bottom: -32px;
            }

            @include tab {
              height: 145px;
              margin-bottom: -32px;
            }

            @include l-sp {
              height: 100px;
              margin-bottom: -22px;
            }

            @include sp {
              height: 72.5px;
              margin-bottom: -16px;
            }
          }
        }

        .image::before {
          content: "2";
        }
      }

      li:nth-child(3) {
        .image {
          img {
            @include pc {
              height: 160px;
              margin-bottom: -40px;
            }

            @include tab {
              height: 160px;
              margin-bottom: -40px;
            }

            @include l-sp {
              height: 105px;
              margin-bottom: -30px;
            }

            @include sp {
              height: 80px;
              margin-bottom: -20px;
            }
          }
        }

        .image::before {
          content: "3";
        }
      }
    }
  }
}
