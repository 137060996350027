.question {
  width: 100%;
  background: #e9f6fe;

  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    @include pc {
      padding: 110px calc((100% - 1080px) / 2) 59px;
      margin: 0 60px;
    }

    @include tab {
      padding: 103px 24px 88px;
      letter-spacing: 0.06em;
    }

    @include l-sp {
      padding: 72px 18px 12px;
    }

    @include sp {
      padding: 51px 12px 44px;
    }

    h2 {
      font-weight: 800;
      text-align: center;

      @include pc {
        font-size: 32px;
        letter-spacing: 0.06em;
        margin-bottom: 60px;
      }

      @include tab {
        font-size: 40px;
        margin-bottom: 78px;
      }

      @include l-sp {
        font-size: 26px;
        margin-bottom: 52px;
      }

      @include sp {
        font-size: 20px;
        margin-bottom: 39px;
      }
    }
  }

  &__contents {
    @include pc {
      display: flex;
      flex-wrap: wrap;
    }

    @include tab {
      margin-right: 60px;
    }

    @include sp {
      margin-right: 30px;
    }
  }

  &__content {
    &:nth-child(odd) {
      margin-right: 64px;
    }

    @include pc {
      width: calc(50% - 32px);
    }

    @include tab {
      width: 100%;
    }
  }

  &__comment {
    position: relative;

    @include pc {
      line-height: 2.5em;
      font-size: 16px;
    }

    @include tab {
      font-size: 28px;
      line-height: 2.07em;
    }

    @include l-sp {
      font-size: 19px;
    }

    @include sp {
      font-size: 14px;
    }

    &--question {
      font-weight: bold;

      @include pc {
        margin: 0 0 32px 62px;
      }

      @include tab {
        margin: 0 0 40px 124px;
      }

      @include l-sp {
        margin: 0 0 28px 80px;
      }

      @include sp {
        margin: 0 0 20px 62px;
      }

      &::before {
        content: "Q";
        background: #00beff;
      }
    }

    &--answer {
      @include pc {
        margin: 0 0 44px 62px;
      }

      @include tab {
        margin: 0 0 66px 124px;
      }

      @include l-sp {
        margin: 0 0 40px 80px;
      }

      @include sp {
        margin: 0 0 33px 62px;
      }

      &::before {
        content: "A";
        background: #ff63a0;
      }
    }

    &::before {
      position: absolute;
      text-align: center;
      display: block;
      border-radius: 50%;
      color: white;
      font-weight: bold;

      @include pc {
        top: -2px;
        left: -62px;
        width: 42px;
        height: 42px;
        line-height: 42px;
      }

      @include tab {
        width: 60px;
        height: 60px;
        line-height: 60px;
        top: -2px;
        left: -88px;
      }

      @include l-sp {
        width: 40px;
        height: 40px;
        line-height: 40px;
        top: -2px;
        left: -58px;
      }

      @include sp {
        width: 30px;
        height: 30px;
        line-height: 30px;
        top: -2px;
        left: -44px;
      }
    }
  }
}
