.service {
  width: 100%;
  background: #e9f6fe;

  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    @include pc {
      padding: 110px calc((100% - 1320px) / 2) 59px;
      margin: 0 60px;
    }

    @include tab {
      padding: 103px 24px 24px;
      letter-spacing: 0.06em;
    }

    @include l-sp {
      padding: 72px 18px 12px;
    }

    @include sp {
      padding: 51px 12px 12px;
    }

    h2 {
      font-weight: 800;
      text-align: center;

      @include pc {
        font-size: 32px;
        letter-spacing: 0.06em;
        margin-bottom: 60px;
      }

      @include tab {
        font-size: 40px;
        margin-bottom: 78px;
      }

      @include l-sp {
        font-size: 26px;
        margin-bottom: 52px;
      }

      @include sp {
        font-size: 20px;
        margin-bottom: 39px;
      }
    }
  }
}

.service-feature {
  li {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 10px 10px 16px 0 #dceefb;

    @include pc {
      display: flex;
      margin-bottom: 32px;
      padding: 60px 120px 68px;
    }

    @include tab {
      display: block;
      width: 100%;
      margin-bottom: 26px;
      padding: 108px 60px 70px;
    }

    @include l-sp {
      padding: 70px 38px 32px;
    }

    @include sp {
      margin-bottom: 13px;
      padding: 54px 30px 35px;
    }

    .text {
      position: relative;

      @include pc {
        margin-right: 48px;
      }

      @include tab {
        margin-bottom: 120px;
      }

      @include l-sp {
        margin-bottom: 52px;
      }

      @include sp {
        margin-bottom: 60px;
      }

      h3 {
        position: relative;
        z-index: 500;
        font-weight: 800;

        @include pc {
          font-size: 24px;
          margin: 44px 0 48px;
        }

        @include tab {
          font-size: 36px;
          line-height: 1.56;
          margin: 0 0 52px;
          letter-spacing: 0.06em;

          @include lineHeightCrop(1.56);
        }

        @include l-sp {
          font-size: 24px;
          margin-bottom: 36px;
        }

        @include sp {
          font-size: 18px;
          margin: 0 0 26px;
        }
      }

      p {
        @include pc {
          font-size: 16px;
          line-height: 2.5;
          margin-bottom: 24px;

          @include lineHeightCrop(2.5);
        }

        @include tab {
          font-size: 28px;
          line-height: 2.07;
          margin-bottom: 60px;

          @include lineHeightCrop(2.07);
        }

        @include l-sp {
          font-size: 19px;
          margin-bottom: 32px;
        }

        @include sp {
          font-size: 14px;
          margin-bottom: 30px;
        }
      }

      small {
        @include pc {
          font-size: 14px;
        }

        @include tab {
          font-size: 20px;
        }

        @include l-sp {
          font-size: 13px;
        }

        @include sp {
          font-size: 10px;
        }
      }
    }
  }

  li:nth-child(1),
  li:nth-child(2),
  li:nth-child(3) {
    .text {
      p::before {
        position: absolute;
        font-weight: 200;
        color: #00beff;
        opacity: 0.24;
        z-index: 0;

        @include pc {
          top: -2px;
          left: 8px;
          font-size: 102px;
        }

        @include tab {
          top: -40px;
          left: 8px;
          font-size: 120px;
        }

        @include l-sp {
          top: -20px;
          font-size: 80px;
        }

        @include sp {
          top: -20px;
          left: 4px;
          font-size: 60px;
        }
      }
    }
  }

  li:nth-child(1) {
    .text {
      p::before {
        content: "01";
      }
    }

    .image {
      img {
        margin-right: -40px;

        @include pc {
          width: 160px;
          margin: 80px 60px 0 80px;
        }

        @include tab {
          width: 220px;
          margin: 0 calc((100% - 220px) / 2);
        }

        @include l-sp {
          width: 160px;
          margin: 0 calc((100% - 160px) / 2) 12px;
        }

        @include sp {
          width: 110px;
          margin: 0 calc((100% - 110px) / 2);
        }
      }
    }
  }

  li:nth-child(2) {
    .text {
      order: 2;

      @include pc {
        margin: 0 0 0 42px;
      }

      p::before {
        content: "02";
      }
    }

    .image {
      order: 1;
      margin-top: -32px;

      img {
        @include pc {
          width: 260px;
          margin: 100px 20px 0 -20px;
        }

        @include tab {
          width: 420px;
          margin: 0 calc((100% - 420px) / 2) 0;
        }

        @include l-sp {
          width: 300px;
          margin: 0 calc((100% - 300px) / 2) 8px;
        }

        @include sp {
          width: 210px;
          margin: 0 calc((100% - 210px) / 2) 0;
        }
      }
    }
  }

  li:nth-child(3) {
    margin-bottom: 0;

    .text {
      p::before {
        content: "03";
      }
    }

    .image {
      img {
        @include pc {
          width: 260px;
          margin: 70px 0 0;
        }

        @include tab {
          width: 460px;
          margin: -20px calc((100% - 460px) / 2) -72px;
        }

        @include l-sp {
          width: 300px;
          margin: 20px calc((100% - 300px) / 2) -20px;
        }

        @include sp {
          width: 230px;
          margin: -20px calc((100% - 230px) / 2) -36px;
        }
      }
    }
  }
}
