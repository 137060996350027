.sp-show {
  @include pc {
    display: none;
  }
}

.pc-show {
  display: none;

  @include pc {
    display: block;
  }
}

.blue {
  color: #00beff;
}

body {
  overflow-x: hidden;
}

// 固定CVボタン
.fixed-btn {
  position: fixed;
  display: flex;
  z-index: 5000;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  background: rgba($color: #11232f, $alpha: 0.5);
  opacity: 0;
  -ms-filter: "alpha( opacity=0 )";
  zoom: 1;
  pointer-events: none;

  @include pc {
    height: 120px;
  }

  @include tab {
    height: 136px;
  }

  @include l-sp {
    height: 90px;
  }

  @include sp {
    height: 68px;
  }

  #close-btn {
    position: absolute;
    background: rgba($color: #11232f, $alpha: 0.2);
    border-radius: 50%;
    pointer-events: painted;

    @include pc {
      bottom: 140px;
      right: 20px;
      width: 40px;
      height: 40px;
    }

    @include tab {
      bottom: 160px;
      right: 24px;
      width: 55.84px;
      height: 55.84px;
    }

    @include l-sp {
      bottom: 108px;
      right: 16px;
      width: 36px;
      height: 36px;
    }

    @include sp {
      bottom: 80px;
      right: 12px;
      width: 27.92px;
      height: 27.92px;
    }

    &::before {
      content: '';
      position: absolute;
      color: #ffffff;
      line-height: 1;
      background: currentColor;
      transform: rotate(45deg);
      pointer-events: painted;

      @include pc {
        top: 19px;
        left: 12px;
        width: 1em;
        height: 0.1em;
      }

      @include tab {
        top: 26px;
        left: 16px;
        width: 24px;
        height: 4px;
      }

      @include l-sp {
        top: 17px;
        left: 10px;
        width: 16px;
        height: 3px;
      }

      @include sp {
        top: 13px;
        left: 8px;
        width: 12px;
        height: 2px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      color: #ffffff;
      line-height: 1;
      background: currentColor;
      transform: rotate(-45deg);
      pointer-events: painted;

      @include pc {
        top: 19px;
        left: 12px;
        width: 1em;
        height: 0.1em;
      }

      @include tab {
        top: 26px;
        left: 16px;
        width: 24px;
        height: 4px;
      }

      @include l-sp {
        top: 17px;
        left: 10px;
        width: 16px;
        height: 3px;
      }

      @include sp {
        top: 13px;
        left: 8px;
        width: 12px;
        height: 2px;
      }
    }
  }

  a {
    position: relative;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(to right, #00aeff, #008aff);
    border-radius: 4px;
    z-index: 5001;
    font-weight: 800;
    pointer-events: painted;

    @include pc {
      width: 440px;
      height: 70px;
      line-height: 70px;
      font-size: 16px;
    }

    @include tab {
      width: 486px;
      height: 88px;
      line-height: 88px;
      font-size: 28px;
    }

    @include l-sp {
      width: 324px;
      height: 60px;
      line-height: 60px;
      font-size: 18px;
    }

    @include sp {
      width: 243px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
    }

    .button-label {
      position: absolute;
      z-index: 5002;
      border-radius: 50%;
      background: #ff63a0;
      color: #ffffff;

      @include pc {
        top: -34px;
        right: 76px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
      }

      @include tab {
        top: -38px;
        right: 20px;
        width: 86px;
        height: 86px;
        line-height: 86px;
        font-size: 22px;
      }

      @include l-sp {
        top: -20px;
        right: 15px;
        width: 52px;
        height: 52px;
        line-height: 52px;
        font-size: 14px;
      }

      @include sp {
        top: -19px;
        right: 10px;
        width: 43px;
        height: 43px;
        line-height: 43px;
        font-size: 11px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      z-index: -1;
      transition: all 0.3s ease 0s;
      background: linear-gradient(to right, #00beff, #0096ff);

      @include pc {
        width: 440px;
        height: 70px;
      }

      @include tab {
        width: 486px;
        height: 88px;
      }

      @include l-sp {
        width: 324px;
        height: 60px;
      }

      @include sp {
        width: 243px;
        height: 44px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin: auto;
      transform: rotate(46deg);
      border-style: solid;
      border-color: #ff63a0 transparent transparent transparent;

      @include pc {
        right: 122px;
        top: 14px;
        border-width: 10px 6px 0 6px;
      }

      @include tab {
        right: 82px;
        top: 30px;
        border-width: 14px 10px 0 10px;
      }

      @include l-sp {
        right: 52px;
        top: 18px;
        border-width: 14px 8px 0 8px;
      }

      @include sp {
        right: 41px;
        top: 15px;
        border-width: 7px 5px 0 5px;
      }
    }

    &:hover::before {
      opacity: 0;
    }
  }
}
