.terms {
  position: relative;
  background: #ffffff;
  z-index: 100;

  @include pc {
    width: calc(100% - 120px);
    padding: 100px calc((100% - 1160px) / 2) 110px;
    margin: 0 60px;
  }

  @include tab {
    padding: 110px calc((100% - 750px) / 2) 100px;
    margin: 116px 60px 12px;
  }

  @include l-sp {
    padding: 48px calc((100% - 500px) / 2) 60px;
    margin: 0 40px 12px;
  }

  @include sp {
    padding: 50px calc((100% - 375px) / 2) 50px;
    margin: 58px 30px 6px;
  }

  h2 {
    font-weight: 800;
    line-height: 1.5;

    @include pc {
      font-size: 32px;
      margin: 110px 0 60px;
    }

    @include tab {
      font-size: 40px;
      margin: 100px 0 60px;
    }

    @include l-sp {
      font-size: 28px;
    }

    @include sp {
      font-size: 20px;
      margin: 50px 0 30px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  h3 {
    font-weight: 800;
    line-height: 1.7;

    @include pc {
      font-size: 24px;
      margin: 80px 0 40px;
    }

    @include tab {
      font-size: 36px;
      margin: 80px 0 40px;
    }

    @include l-sp {
      font-size: 24px;
    }

    @include sp {
      font-size: 18px;
      margin: 40px 0 20px;
    }
  }

  h4 {
    font-weight: 800;

    @include pc {
      font-size: 18px;
      margin: 60px 0 32px;
    }

    @include tab {
      font-size: 28px;
      margin: 60px 0 32px;
    }

    @include l-sp {
      font-size: 20px;
    }

    @include sp {
      font-size: 14px;
      margin: 30px 0 16px;
    }
  }

  p {
    line-height: 2;

    @include pc {
      font-size: 16px;
    }

    @include tab {
      font-size: 28px;
    }

    @include l-sp {
      font-size: 18px;
      line-height: 2.3;
    }

    @include sp {
      font-size: 14px;
    }

    a {
      color: #00beff;
    }

    .space {
      @include pc {
        margin-right: 14px;
      }
    }
  }

  p + p {
    @include pc {
      margin-top: 40px;
    }

    @include tab {
      margin-top: 40px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  ol {
    @include pc {
      margin: 40px 0;
    }

    @include tab {
      margin: 40px 0;
    }

    @include sp {
      margin: 20px 0;
    }

    li {
      line-height: 2;

      @include pc {
        font-size: 18px;
      }

      @include tab {
        font-size: 28px;
      }

      @include l-sp {
        font-size: 18px;
        line-height: 2.3;
      }

      @include sp {
        font-size: 14px;
      }
    }

    .nest-list {
      @include pc {
        margin-left: 32px;
      }
    }
  }

  ol.number-list {
    @include pc {
      margin: 40px 0;
    }

    li {
      list-style-type: none;
      counter-increment: cnt;

      &::before {
        content: "(" counter(cnt) ") ";
      }
    }
  }
}
