.footer {
  width: 100%;

  &__inner {
    display: flex;
    background: #042130;

    @include pc {
      padding: 72px 60px 88px;
    }

    @include tab {
      padding: 114px 60px 120px;
    }

    @include l-sp {
      padding: 76px 38px 80px;
    }

    @include sp {
      padding: 57px 30px 60px;
    }

    // 例外指定
    @media screen and (max-width: 360px) {
      padding: 57px 14px 60px;
    }

    a,
    small {
      color: #ffffff;
    }

    a {
      @include pc {
        font-size: 12px;
      }

      @include tab {
        font-size: 20px;
      }

      @include l-sp {
        font-size: 14px;
      }

      @include sp {
        font-size: 10px;
      }
    }

    small {
      margin-left: auto;

      @include pc {
        font-size: 12px;
      }

      @include tab {
        font-size: 20px;
      }

      @include l-sp {
        font-size: 14px;
      }

      @include sp {
        font-size: 10px;
      }
    }
  }
}
