.cv {
  width: 100%;
  background: -moz-linear-gradient(-48deg, rgb(0, 190, 255) 0%, rgb(0, 150, 255) 100%);
  background: -webkit-linear-gradient(-48deg, rgb(0, 190, 255) 0%, rgb(0, 150, 255) 100%);
  background: -ms-linear-gradient(-48deg, rgb(0, 190, 255) 0%, rgb(0, 150, 255) 100%);

  @include pc {
    padding: 0 calc((100% - 1440px) / 2);
  }

  @include tab {
    padding: 0 calc((100% - 750px) / 2);
  }

  @include l-sp {
    padding: 0 calc((100% - 550px) / 2);
  }

  @include sp {
    padding: 0 calc((100% - 375px) / 2);
  }

  &__inner {
    display: flex;

    @include pc {
      align-items: flex-start;
      padding: 75px calc((100% - 1080px) / 2);
      margin: 0 60px;
    }

    @include tab {
      flex-direction: column;
      padding: 108px 0;
      margin: 0 60px;
    }

    @include l-sp {
      padding: 72px 0;
      margin: 0 40px;
    }

    @include sp {
      padding: 54px 0;
      margin: 0 30px;
    }
  }
}

.cv-summary {
  &__text {
    @include tab {
      text-align: center;
    }

    h2 {
      font-weight: 800;
      color: #ffffff;

      @include pc {
        font-size: 22px;
        line-height: 1.82;

        @include lineHeightCrop(1.82);
      }

      @include tab {
        text-align: center;
        font-size: 36px;
        line-height: 1.72;
        letter-spacing: 0.06em;

        @include lineHeightCrop(1.72);
      }

      @include l-sp {
        font-size: 24px;
      }

      @include sp {
        font-size: 18px;
      }
    }

    .detail {
      display: inline-block;

      @include pc {
        font-size: 14px;
        line-height: 2.29;
        margin-top: 28px;

        @include lineHeightCrop(2.29);
      }

      @include tab {
        text-align: center;
        font-size: 24px;
        line-height: 1.83;
        letter-spacing: 0.1em;
        margin-top: 84px;

        @include lineHeightCrop(2.29);
      }

      @include l-sp {
        font-size: 16px;
        margin-top: 52px;
      }

      @include sp {
        font-size: 12px;
        margin-top: 42px;
      }
    }
  }

  &__btn {
    position: relative;
    text-align: center;
    color: #0096ff;
    background: #ffffff;
    border-radius: 4px;
    z-index: 100;
    font-weight: 800;
    transition: margin 0.3s;
    box-shadow: 0 10px 30px rgba(0, 129, 219, 1);

    @include pc {
      width: 284px;
      height: 80px;
      font-size: 18px;
      line-height: 80px;
      margin-left: auto;
    }

    @include tab {
      width: 446px;
      height: 110px;
      font-size: 32px;
      line-height: 110px;
      margin: 80px calc((100% - 446px) / 2) 0;
      letter-spacing: 0.05em;
    }

    @include l-sp {
      width: 300px;
      height: 76px;
      font-size: 20px;
      line-height: 80px;
      margin: 52px calc((100% - 300px) / 2) 0;
    }

    @include sp {
      width: 223px;
      height: 55px;
      font-size: 16px;
      line-height: 55px;
      margin: 40px calc((100% - 223px) / 2) 0;
    }

    &:hover {
      box-shadow: 0 20px 30px rgba(0, 129, 219, 1);

      @include pc {
        margin-top: -3px;
        margin-bottom: 3px;
      }

      @include tab {
        margin-top: 77px;
        margin-bottom: 3px;
      }

      @include l-sp {
        margin-top: 49px;
        margin-bottom: 3px;
      }

      @include sp {
        margin-top: 37px;
        margin-bottom: 3px;
      }
    }

    .button-label {
      position: absolute;
      z-index: 100;
      border-radius: 50%;
      background: #ff63a0;
      color: #ffffff;

      @include pc {
        top: -26px;
        right: -18px;
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
      }

      @include tab {
        top: -40px;
        right: -24px;
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: 22px;
      }

      @include l-sp {
        top: -28px;
        right: -12px;
        width: 58px;
        height: 58px;
        line-height: 58px;
        font-size: 16px;
      }

      @include sp {
        top: -20px;
        right: -12px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 11px;
      }
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   border-radius: 4px;
    //   z-index: -1;
    //   transition: all 0.3s ease 0s;
    //   background: linear-gradient(to right, #00beff, #0096ff);

    //   @include pc {
    //     width: 284px;
    //     height: 80px;
    //   }

    //   @include tab {
    //     width: 446px;
    //     height: 110px;
    //   }

    //   @include l-sp {
    //     width: 300px;
    //     height: 76px;
    //   }

    //   @include sp {
    //     width: 223px;
    //     height: 55px;
    //   }
    // }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin: auto;
      transform: rotate(46deg);
      border-style: solid;
      border-color: #ff63a0 transparent transparent transparent;

      @include pc {
        right: 28px;
        top: 24px;
        border-width: 10px 6px 0 6px;
      }

      @include tab {
        right: 40px;
        top: 25px;
        border-width: 12px 8px 0 8px;
      }

      @include l-sp {
        right: 30px;
        top: 16px;
        border-width: 12px 8px 0 8px;
      }

      @include sp {
        right: 20px;
        top: 12.5px;
        border-width: 6px 4px 0 4px;
      }
    }

    // &:hover::before {
    //   opacity: 0;
    // }
  }
}
