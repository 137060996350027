#return-top {
  display: block;
  width: 100%;
  background: #05293b;
  transition: all 0.3s ease 0s;

  @include pc {
    padding: 40px;
  }

  @include tab {
    padding: 58px;
  }

  @include l-sp {
    padding: 38px;
  }

  @include sp {
    padding: 29px;
  }

  p {
    text-align: center;
    font-weight: 800;
    color: white;

    @include pc {
      font-size: 14px;
      margin-right: -20px;
    }

    @include tab {
      font-size: 24px;
    }

    @include l-sp {
      font-size: 16px;
    }

    @include sp {
      font-size: 12px;
    }
  }

  p::after {
    content: '';
    display: inline-block;
    transform: rotate(-45deg);

    @include pc {
      width: 12px;
      height: 12px;
      border-top: solid 2px;
      border-right: solid 2px;
      margin-left: 12px;
      margin-bottom: -4px;
    }

    @include tab {
      width: 22px;
      height: 22px;
      border-top: solid 4px;
      border-right: solid 4px;
      margin-left: 24px;
      margin-bottom: -8px;
    }

    @include l-sp {
      width: 14px;
      height: 14px;
      border-top: solid 2px;
      border-right: solid 2px;
      margin-left: 12px;
      margin-bottom: -6px;
    }

    @include sp {
      width: 11px;
      height: 11px;
      border-top: solid 2px;
      border-right: solid 2px;
      margin-left: 12px;
      margin-bottom: -4px;
    }
  }

  &:hover {
    background: #063146;
  }
}
