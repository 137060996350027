@mixin pc {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin tab {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin l-sp {
  @media screen and (max-width: 749px) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: 499px) {
    @content;
  }
}
